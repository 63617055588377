@import 'global';

.SuggestionsSheet {
    min-height: 306px;

    .category-content {
        display: flex;
    }

    .category-work-img {
        margin: 17.5px 12px 0 auto;
        width: 26px;
        align-self: center;
    }

    .category-heading {
        float: left;
        margin: 12px 0 0 0px;
        //margin: 12px auto;
        padding: 0.7em 1.5em;
        background-color: #eee;

        font-size: 10pt;
        font-weight: 800;
        border-radius: 100px;

        background-color: #e2e3ff;
        color: lighten(#4447A1, 10%);

        background-color: lighten(#d7f3ff, 2%);
        color: darken(#29b0e7, 10%);

        background-color: darken(#29b0e7, 0%);
        color: #fff !important;
        zoom: 0.9;


        // background-color: lighten(#f5ebd1, 7%);
        // color: darken(#f2c353, 20%);
        //color: #fff;
    }

    .options {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        padding-bottom: 24px;

        zoom: 0.95;
        zoom: 1.00;
    }

    .toolbar {
        display: flex;
        height: 40px;
        margin-top: -16px;
        zoom: 0.85;
        opacity: 1;

        .empty-space {
            width: 25%;
            min-width: 25%;
            display: none;
        }

        .score {
            display: flex;
            width: 27.5%;
            height: 54px;
            background-color: #fff;
            border-radius: 100px;
            margin: 0 0px;
            margin-left: 6px;
            margin-left: auto;
            //flex: 1;
            //background-color: green;

            opacity: 0;

            .score-circle {
                width: 12px;
                height: 12px;
                margin: 20px 5px 0 20px;
                border-radius: 100px;
                background-color: #ffc45e;
                //border: 3px solid #ffc45e;
                //background-color: #fff;
                opacity: 0.5;
            }

            .score-number {
                padding: 14px 0;
                font-size: 13.0pt;
                font-weight: 600;
                color: #0ea2df;
            }
        }

        .refresh-container2 {
            //margin-left: auto;
            //flex: 1;
            //background-color: red;
            margin-left: auto;
            margin-left: 6px;
            margin-right: 14px;
        }

        .RefreshButton {
            width: 54px;
            height: 54px;
            background-color: #fff;
            border-radius: 100px;
            transition: .2s;

            .refresh-icon-img {
                width: 24px;
                margin-top: 15px;
            }
        }

        .RefreshButton:hover {
            cursor: pointer !important;
            transform: scale(1.4);
            box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);
        }
    }

}

.SuggestionsSheet.Work {
    .category-heading {
        // background-color: lighten(#d9f9e7, 2%);
        // background-color: darken(#33d8e2, 0%);
        // color: darken(#3bcb7b, 10%);
        background-color: darken(#29b0e7, 9%);
        color: #fff !important;
    }
}

.SuggestionsSheet.Personal {
    .category-heading {
        opacity: 1;
        background-color: lighten(#d9f9e7, 2%);
        background-color: darken(#3bcb8c, 9%);
        //background-color: darken(#23bba0, 0%);
        color: darken(#3bcb7b, 10%);
    }
}

.SuggestionsSheet.Reinforce {

    .toolbar {
        .score {
            opacity: 0;
        }
    }

    .category-heading {
        background-color: lighten(#d9f9e7, 2%);
        background-color: #ff8e42;
        background-color: darken(#ffba42, 13%);
        color: darken(#ee9b69, 10%);
    }

    .SuggestionOption {


        //font-size: 13.pt;
        //opacity: 0.9;

        .label {}

        .label-content {
            color: $reinforceColor;

        }
    }
}


.SuggestionOption {

    width: 100%;
    height: 115.5px;
    margin: 10px 8px -5px 0;
    //height: 118.5px;
    //height: 92px;
    box-sizing: border-box;

    font-size: 14.0pt;
    font-weight: 500;
    padding: 1.1em 28px 1.1em 24px;
    padding-top: 0.95em;

    text-align: left;
    //text-align: center;
    line-height: 1.45em;

    color: darken(#0ea5e4, 1%);
    color: darken(#0ea5e4, 4%);
    color: #0ea2df;
    color: #04a8eb;
    //color: #222;
    //color: #135ec4;
    border-radius: 12px;
    border-radius: 12px;



    background-color: lighten(#d1eff7, 11%); // Was 10% for long time
    //background-color: #fff;
    //box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);

    transition: 0.3s;

    //zoom: 0.95;

    //background-color: transparent;

    .label {

        //zoom: 0.975;

        //opacity: .92;

        // Was 12.5pt
        font-size: 12.5pt;
        font-size: 12.25pt;
        font-size: 11.75pt;
        //font-size: 12pt;
        //font-size: 12.25pt;
        //font-size: 13pt;
        padding: 1.75em 60px;
        padding: 1.75em 50px;
        padding: 1.75em 30px;
        padding: 1.5em 20px 1.75em 20px;
        padding-right: 0px;

        padding: 0;

        line-height: 1.42em;
        box-sizing: border-box;

        color: $negativeColor;
        //color: #777;

        transition: .25s;

        text-align: left;
        //text-indent: -18px;
        //padding-left: 38px;
    }


    .label-content {
        // text-align: center;
        // vertical-align: middle;

        // display: flex;
        // justify-content: center;
        // /* Align horizontal */
        // align-items: center;
        // /* Align vertical */

        // height: 38px;
    }

    .original-label {
        padding-top: 8px;
        font-size: 11.0pt;
        text-decoration: line-through;
        color: $negativeColor;
        opacity: 0.75;
        line-height: 1.45em;

        opacity: 0;
    }


    .thought-checks {
        //zoom: 0.675 !important;

        //margin-top: auto;
        margin-top: 10px;
        //margin-top: auto;
        //margin-top: 22px;
        //margin-left: auto;

        height: 30px;
        //background-color: #ddd;

        display: flex;

        transition: .5s;

        .thought-check {
            width: 16px;
            height: 16px;
            border: 2px solid darken(#ffc45e, 0%);

            //background-color: #e75e47;

            opacity: 0.6;
            border-radius: 100px;
            margin: 0 8px 0 0;

            .checkmark-img {
                opacity: 0;
                width: 10px;
            }
        }

        .thought-check.Completed {
            //@include: #0ea2df;
            //background-color: #e75e47;
            opacity: 1;
            //border: 2px solid darken(#ffc45e, 0%);

            .checkmark-container {
                margin-top: -7.5px;
                margin-top: -9.5px;
                margin-left: 3px;
            }

            .checkmark-img {
                opacity: 1;
                padding: 0;
                //margin-top: -40px !important;
            }
        }
    }
}


.SuggestionOption {
    .thought-checks {
        margin-top: 13.5px;
        zoom: 0.775;
    }

    .thought-tags {
        display: none;
    }
}

.SuggestionOption.Reinforce {
    //height: 236px;
    height: 155.5px;

    padding-bottom: 12px !important;
    padding-right: 28px !important;
    padding-left: 24px !important;

    display: flex;
    flex-direction: column;

    transition: 0.35s;

    .thought-checks {
        margin-top: auto;
        zoom: 0.85;
    }

    .label {
        //font-size: 12pt;
        //font-size: 14.0pt;
        // font-size: 13.75pt;
        // font-size: 13.0pt;

        // font-size: 13.25pt;
        // font-size: 12.5pt;
        font-size: 12.25pt;
    }

    .preview-content {
        display: flex;
        flex-direction: column;
        height: 206px;

        display: none;
    }

    .thought-set-content {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .area-icon {
        margin-left: auto;
        margin-top: -4px;
        margin-right: -8px;
    }

    .area-img {

        width: 20px;
    }
}

// Switch Reinforce card content based on if it is
// a Suggest Card or has already been clicked.
.SuggestionOption.Reinforce {
    .preview-content {
        display: flex;
    }

    .thought-set-content {
        display: none;
    }
}

.SuggestionOption.ReinforceInProgress {
    transition: .3s;

    .preview-content {
        display: none;
    }

    .thought-set-content {
        display: flex;
    }
}

.SuggestionOption.ReinforceInProgress.SlideAway {
    opacity: 0;
    transition: .4s;
}

.SuggestionOption.Reinforce.IsEmpty {
    opacity: 0.5;

    .empty-label {
        text-align: center;
        padding: 80px 40px 0 40px;

        font-size: 10.5pt;
        line-height: 1.35em;
    }
}

.SuggestionOption.Reinforce.IsEmpty:hover {
    cursor: default;
    transform: none;
    box-shadow: none;
}

.SuggestionOption:hover {
    cursor: pointer;
    transform: scale(1.05);
    box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);

    .thought-checks {
        //opacity: 0.5;
    }
}

.SuggestionOption.Selected {
    opacity: 0 !important;
    //height: 0px !important;
}

.SuggestionOption.SelectedClone {
    padding-left: 22px !important;
    padding-top: 18.5px !important;

    .thought-checks {
        opacity: 0 !important;
    }

    .label-content {
        transform: scale(0.99) !important;
        transform-origin: left;
        line-height: 1.40em;
    }
}

.SuggestionOption.Reinforce.SessionThoughtExpanded {
    height: 146px;

    .label-content {
        padding-top: 3px;
        font-size: 14.5pt;
        color: $reinforceColor;
    }

    .thought-checks {
        margin-top: 2px;
        transform: scale(.875);
        transform-origin: left;
    }
}

// Shared
.thought-tags {
    display: flex;
    margin-top: -20px;
    margin-left: -0px;
    margin-top: auto;

    .thought-tag {
        //zoom: 0.95;
        //opacity: 0.8;
        padding: 2px 0px;
        border-radius: 100px;
        margin-right: 10px;
        font-size: 9.75pt;
        font-weight: 700;
        color: #fff;
    }

    .tag-self-esteem {
        color: darken(#ffbd75, 10%);

    }

    .tag-calm-mind {
        color: lighten(#c675ff, 1%);

    }
}