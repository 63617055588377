@import 'global';

.ThoughtSetContent {
    display: flex;
    flex-direction: column;
    height: 155px;

    .card-number-position {
        position: relative;
        opacity: 1;

        .card-number {
            position: absolute;
            transform: scale(1.3);
            left: 276px;
            top: 82px;
            transition: .5s;
            //opacity: 0;
            z-index: 1000;
        }

        .card-content {
            width: 64px;
            height: 56px;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            justify-items: center;
        }

        .check-img {
            width: 16px;
            align-self: center;
        }

        .number-label {
            padding: 0 0 0 4px;
            font-size: 15pt;
            font-weight: 600;
            color: $positiveAccentColor !important;
        }
    }

    .card-icon-position {
        position: relative;
        opacity: 1;
        display: none;

        .card-icon {
            position: absolute;
            transform: scale(1.3);
            left: -6px;
            top: 96.5px;
            top: 24.5px;

            width: 26px;
            height: 46px;
            background-color: #fff;
            border: 1.5px solid lighten(#decda7, 17%);
            border-bottom: 1.5px solid lighten(#decda7, 12%);
            border-right: 0;
            border-bottom: 0;

            border-radius: 14px 0 0 14px;

            transition: .5s;
            //opacity: 0;
            z-index: 1000;

            transform: scale(0.97);

            border: 0;
            left: 14px;
        }

        .card-content {
            // width: 64px;
            // height: 56px;
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            justify-items: center;
        }

        .check-img {
            width: 19px;
            align-self: center;
            margin: 16px 0 0 25px;

            //margin: 80px 0 0 25px;

            //width: 21px;
        }
    }

    .checklist {
        margin-left: -32px !important;
        margin-left: -2px !important;


    }

    .thought-tag {
        //opacity: 0 !important;
    }

    .original-content {
        padding-top: 0;
        margin-top: -2px;

    }

    .progress-content {
        display: flex;
        margin-top: auto;
        zoom: 0.85;
        zoom: 0.875;
        // zoom: 0.9;
    }

    .thought-tags {
        margin-top: auto;
        opacity: 1;
    }

    .label-content {
        width: 310px !important;
        min-width: 310px !important;
        max-width: 310px !important;

        .thought-label {
            padding-top: 6.5px;
            font-size: 11.25pt !important; // Was 11.25pt
            line-height: 1.4em !important;
            font-weight: 600;
            padding-right: 20px;
        }
    }

    .reframed-content {
        margin-top: -12.5px !important;
        padding-top: 9px !important;
    }

    .original-container {
        margin-top: -3.5px !important;
    }

    .flat-content {
        display: flex;
        flex-direction: column;
        margin: 9.0px 9px 11px 10px;
        //height: 200px !important;
        padding: 16px 0px 14px 24px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0.5px 0.5px 2px .0px darken(#e9dec5, 8%); // Was 20%
        transition: .4s;

        position: relative;
        top: 0px;
        z-index: 10;

        // transform: scale(1.01);
        // transform: scale(0.97);
    }

    .thought-tags {
        margin-top: 7px;
        margin-left: 4px;
    }

    .area-icon {
        margin-left: auto;
        margin-top: -4px;
        margin-right: -8px;
    }

    .area-img {
        width: 20px;
    }
}

.ThoughtSetContent.Work {
    .card-number-position {
        .number-label {
            color: $workColor;
        }
    }
}

.ThoughtSetContent.Personal {
    .card-number-position {
        .number-label {
            color: $personalColor;
        }
    }
}

.ThoughtSetContent.Reinforce {
    .card-number-position {
        .number-label {
            color: $positiveAccentColor;
        }
    }
}

.ThoughtSetContent.IsFlat {
    .flat-content {
        height: 100%;
        margin: 0;
        padding: 0;
        box-shadow: none;
    }
}

.ThoughtSetContent.IsIncomplete {

    height: 100%;

    .reframed-content {
        padding-top: 7px !important;
    }

    .reframed-label {
        font-size: 14.5pt !important;
        font-weight: 500 !important;
    }

    .label-content {
        width: 98% !important;
        min-width: 98% !important;
        max-width: 98% !important;
    }

    .thought-tags {
        margin-top: -2.5px;
        margin-left: -5px;
    }

    .card-number-position {
        opacity: 0;
    }

    .original-container {
        display: none;
    }

    .original-content {
        opacity: 0;
    }


}

// Tweaks to CSS because there are conflicts with 
// when .SessionThoughtCompleted is also applied
.ThoughtSetContent.IsIncomplete {
    .thought-checks {
        transform: scale(1.3) !important;
        transform-origin: left;
        display: flex;
        width: 75%;

        .checkmark-container {
            margin-top: -12px !important;
        }
    }

    .thought-tags {
        //zoom: 1.2;

        .thought-tag {}
    }
}

.ThoughtSetContent.ShowCheck {
    .card-number-position {
        .card-number {
            transform: scale(1.3);
            opacity: 1;
        }
    }
}

.ThoughtSetContent.HideCheck {
    .card-number-position {
        .card-number {
            transform: scale(2.1);
            opacity: 0;
        }
    }
}

.ThoughtSetContent.HideNumber {
    .card-number-position {
        .card-number {
            opacity: 0;
        }
    }
}

.SessionThoughtCompleted {
    .ThoughtSetContent {
        .flat-content {
            height: 100%;
        }
    }
}

.area-tags {
    display: flex;
    width: 90%;
    justify-content: flex-end;
    margin: 0 24px 0 0;

    transform: scale(1);
}

$tagWidth: 26px;

.AreaTag.AreaTagMood {
    //margin-left: -1px;
}

.AreaTag.AreaTagFocus {
    //margin-left: -1px;

    .area-img {
        margin-top: 5px;
    }
}

.AreaTag.AreaTagOpenness {
    //margin-left: 0.5px;
}

.AreaTag.AreaTagEase {
    //margin-left: 1.5px;
}

.AreaTag.AreaTagMotivation {
    .area-img {
        margin-top: 4px;
    }
}

.AreaTag.Big {
    width: 32px;
    max-width: 32px;
    min-width: 32px;

    transform: scale(1.1);
}

.AreaTag.Small {
    width: 32px;
    max-width: 32px;
    min-width: 32px;

    transform: scale(.7);
}

.AreaTag {

    display: flex;

    background-color: lighten($positiveAccentColor, 57%);
    border-radius: 100px;
    margin: 2px 1px 0 0;
    margin: 2px 3px 0 3px;
    width: $tagWidth;
    max-width: $tagWidth;
    min-width: $tagWidth;

    .area-icon {
        width: 28px;
        min-width: 28px;
        max-width: 28px;
        height: 28px;
        box-sizing: border-box;
        border-radius: 14px;
        margin: 3px 12px 0 4px;
        // background-color: #fff !important;
        // box-shadow: 0.5px 0.5px 2px 0px #cdb47b;
        //border: 1px solid lighten(#cdb47b, 32%);

        transform: scale(1.3);
    }

    .area-img {
        width: 16px;
        align-self: flex-start;
        //align-self: flex-start;
        margin: 7.5px 6px 0 -6px;

        margin: 5px 3px 0 2px;
    }

    .area-ease {
        width: 15.0px;
        margin-top: 6px;
        margin-left: 4.0px;
    }

    .area-openness {
        width: 16px;
        margin: 7px 0px 0 -1px;
        opacity: .8;
    }

    .area-focus {
        //margin: 6px 0px 0 -4px;
        margin-top: 6px;
    }

    .area-motivation {
        width: 17px;
        margin: 6px 0px 0 0px;
    }

    .area-purpose {
        margin-top: 6px;
    }

    .area-mood {
        width: 15px;
        margin-top: 6.5px;
        margin-right: 1.5px;
    }

    .area-points {
        //display: none;
        color: lighten($positiveAccentColor, 0%);
        font-weight: 600;
        font-size: 11.5pt;
        margin: 6px 4px 4px -10px;
        padding: 0 8px 0 0;

        .plus-sign {
            display: none;
            //opacity: .5;
            padding-right: 0.5px;
        }
    }
}