// Was 788px. Not sure yet.
$width: 800px;

.NegativeGrid {
    flex: 1;
    width: $width;
    min-width: $width;
    max-width: $width;

    margin-left: -6px;

    transition: .3s;

    //zoom: 1.05;
}