@import 'global';

.ThoughtPostRating {
    flex: 1;
    width: 860px;
    min-width: 860px;
    max-width: 860px;

    transition: .3s;
    opacity: 1;
    margin-left: 0px;

    .sheet-heading {
        padding: 10px 22px 4px 14px;
        padding: 0;
        color: darken(#0ea5e4, 1%);
        font-size: 11pt;
        font-weight: 700;
        text-align: left;
    }

    .sheet-heading-reframing {}

    .sheet-heading-rerate {
        margin-bottom: 10px;
        //background-color: $positiveAccentColor;
        margin-top: -16px;
    }

    .category-heading {
        float: left;
        margin: -2px 0 0 -8px;
        //margin: 12px auto;
        padding: 0.7em 1.5em;
        background-color: #eee;

        font-size: 10pt;
        font-weight: 800;
        border-radius: 100px;

        background-color: #e2e3ff;
        color: lighten(#4447A1, 10%);

        background-color: lighten(#d7f3ff, 2%);
        color: darken(#29b0e7, 10%);

        background-color: darken(#29b0e7, 0%);
        color: #fff !important;
        zoom: 0.9;

        background-color: darken(#f2845a, 0%);
        background-color: $positiveAccentColor;
    }

    .category-heading-reframe {
        margin-left: -8px;
    }

    .sheet {
        width: 380px;
        //height: 101px;
        // background-color: #fff;
        // border-radius: 18px;

        // padding: 24px 24px;

        margin-left: 0px;
    }

    .sheet.Expanded {
        //box-shadow: 1px 2px 3px 1px lighten(#e3e6f7, 0%);
    }


    .reframing-refresh-icon-img {
        width: 28px;
        margin-top: 8px;
    }

    .reframing-refresh-button {
        color: darken(#0ea5e4, 2%);
        //color: #222;
        //color: #135ec4;
        border-radius: 24px;
        //margin: 8px 8px 4px 0;

        width: 40px;
        margin: 0px auto;
        //float: right;

        padding: 26px 0;

        transition: .3s all;
        cursor: pointer;

        //background-color: lighten(#d1eff7, 10%);
        //box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);
    }

    .reframing-refresh-button:hover {
        transform: scale(1.2);
    }

    .picking-content {
        opacity: 0;
        transition: .3s;
        margin-top: 20px;

        height: 0px;

        //margin-left: -8px;


        margin-top: 26px;
    }

    .picking-content.Hidden {
        display: none;
    }

    .picking-content.Show {
        opacity: 1;
        margin-top: 16px;

        //height: 700px;
    }


    .belief-content {
        opacity: 0;
        height: 0;
        pointer-events: none;
        //transition: .3s;


        //margin-left: -8px;

        margin-top: 32px;

        padding-left: 40px;
    }

    .belief-content.Show {
        opacity: 1;
        height: 300px;
        pointer-events: all;
        //margin-top: 42px;

        padding-left: 0px;
    }

    .reframe-heading {
        padding: 0px 22px 12px 14px;
        margin-top: -16px;
        color: #fd6f53;
    }

    .reframe-sheet {
        background-color: #fff;
        width: 520px;
        height: 221px;
        border-radius: 18px;
        padding: 24px 36px;
        padding-top: 24px;
        padding-bottom: 42px;
        padding-left: 28px;

        margin-left: -8px;

        box-shadow: 1px 2px 3px 1px lighten(#e3e6f7, 0%);
        box-shadow: none;
    }

    .reframe-sheet.SessionCardSize {
        width: 380px;
        height: 149.5px;

        box-sizing: border-box;
        padding: 0px 0 0 28px;

        transition: .35s;
        box-shadow: 1px 2px 3px 1px lighten(#e3e6f7, 0%);
    }

    .reframe-sheet.ReinforceCardShrink {

        position: relative;
        top: 0px;
        left: 0px;
        transform: scale(0.75);
        //transform-origin: center left;

        transition: .5s;
        box-shadow: 1px 3px 4px 2px darken(#e3e6f7, 2%);

        .reframe-meter-container {
            opacity: 0.5;
            transition: .5s;
        }
    }

    .reframe-sheet.ReinforceCardFling {
        position: relative;
        top: -100px;
        left: -400px;
        // margin-left: -300px;
        // margin-top: -60px;
        //opacity: 0;
        transition: .35s;
        z-index: 2000;
    }

    .reframe-sheet.ReinforceCardFade {
        opacity: 0;
        transform: scale(0.5);
        transition: .5s;
    }

    .reframe-meter-container {
        text-align: left;
    }

    .reframe-meter-container.Fade {
        opacity: 0;
        transition: .35s;
    }

    .meter-thought-heading {
        padding-top: 18px;

        display: flex;

        .checkmark-img {
            width: 16px;
            padding: 2px 6px 0 12px;
            align-self: center;
        }
    }

    .meter-thought-heading-new {
        padding-top: 8px;
    }

    .labels-content .label {
        color: #fff !important;
    }

    .meter-thought-heading .label {
        font-size: 14pt !important;
    }

    .meter-thought-heading-new .label {
        color: $reinforceColor !important;
        font-weight: 500;
    }

    .meter-thought-heading .label {
        color: $negativeColor;
        font-weight: 500;
    }

    .reframe-thought-content {
        margin-top: -8px;
    }

    .toolbar {
        display: flex;
        width: 328px;
        height: 40px;
        margin-top: -25px;
        zoom: 0.85;
        opacity: 1;
    }

    .refresh-container2 {
        //margin-left: auto;
        //flex: 1;
        //background-color: red;
        margin-left: auto;
        //margin-left: 6px;
        margin-right: 14px;
    }

    .RefreshButton {
        width: 54px;
        height: 54px;
        background-color: #fff;
        border-radius: 100px;
        transition: .2s;

        .refresh-icon-img {
            width: 24px;
            margin-top: 15px;
        }
    }

    .RefreshButton:hover {
        cursor: pointer !important;
        transform: scale(1.4);
        box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);
    }
}

.ThoughtPostRating.HideContent {
    opacity: 0;
    margin-left: 40px;
}

.ThisSessionThought {
    opacity: 1;
    transition: .25s !important;

    width: 360px;
    background-color: transparent;
    transform: scale(1.1);

    .ThoughtSetContent {
        .flat-content {
            box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);
            border-radius: 16px !important;
        }
    }
}

.ThisSessionThoughtFlat {
    transform: scale(1.0);

    .ThoughtSetContent {
        .flat-content {
            box-shadow: 0.5px 0.5px 2px 0px #decda7;
            border-radius: 10px !important;
        }
    }
}

.ThisSessionThought.Show {
    opacity: 1;
}

.ImprovementMessage {
    position: relative;
    top: -116px;
    left: 340px;
    opacity: 1;
    transition: .3s;
    // transform: scale(1.2);
    // transform-origin: left;

    .improve-bubble {
        width: 140px;
        background-color: darken($backgroundColor, 1%);
        border-radius: 100px;
        padding: 12px 18px;
        font-size: 11.5pt;
        font-weight: 600;
        color: darken(#40b8e9, 10%);
        text-align: center;
    }
}

.ImprovementMessage.Hidden {
    left: 390px !important;
    opacity: 0 !important;

}

.ImprovementMessage.Bounce {
    transform: scale(1.0) !important;
    //animation: messageBounce 1.05s 1 0.1s;
    //messageOneBounce .3s 1 1.05s;
    animation-timing-function: ease-in-out;
}

@keyframes messageBounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
        opacity: 1;
    }

    40% {
        transform: translateY(-10px);
    }

    60% {
        transform: translateY(-10px);
    }

    // 90% {
    //     transform: translateY(0);
    // }

    // 95% {
    //     transform: translateY(-2px);
    // }

    // 100% {
    //     transform: translateY(0);
    // }
}