@import 'global';

.ResponseGrid {
    flex: 1;
    width: 860px;
    min-width: 860px;
    max-width: 860px;

    opacity: 1;
    margin-left: 0px;
    transition: .4s;

    .grid-layout {
        display: flex;
        width: 860px;
        margin-left: -6px;

        .sheet {
            width: 640px;
            min-width: 640px;
            max-width: 640px;
        }

        .controls {
            width: 220px;
            min-width: 220px;
            max-width: 220px;
            height: 510px;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            justify-items: center;
            align-items: flex-start;

            .RefreshButton {
                margin-top: -12px;
                margin-left: -46px;

                width: 52px;
                height: 52px;


                //box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);

                padding: 40px 40px 40px 0;

                transform: scale(1.15);
                transition: 0.2s;

                .refresh-fill {
                    width: 52px;
                    height: 52px;
                    border-radius: 100px;

                    background-color: #fff;
                }

                .refresh-icon-img {
                    width: 22px;
                    padding-top: 15px;
                    align-self: center;
                }
            }

            .RefreshButton:hover {
                cursor: pointer;
                transform: scale(1.25);


                .refresh-fill {
                    box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);
                }
            }
        }
    }


    .sheet-heading {
        // padding: 24px 22px 5px 22px;
        // color: darken(#0ea5e4, 1%);
        // font-size: 11pt;
        // font-weight: 700;
        // text-align: left;
    }

    .category-heading {
        float: left;
        margin: 18px 0 2px -8px;
        //margin: 12px auto;
        padding: 0.7em 1.5em;
        background-color: #eee;

        font-size: 10pt;
        font-weight: 800;
        border-radius: 100px;

        background-color: #e2e3ff;
        color: lighten(#4447A1, 10%);

        background-color: lighten(#d7f3ff, 2%);
        color: darken(#29b0e7, 10%);

        background-color: darken(#29b0e7, 0%);
        color: #fff !important;
        zoom: 0.9;

        background-color: darken(#29c5e7, 0%);

        // color: darken(#29c5e7, 0%) !important;
        // background-color: transparent;

        opacity: 0;


        // background-color: lighten(#f5ebd1, 7%);
        // color: darken(#f2c353, 20%);
        //color: #fff;
    }

    .options {
        //width: 1180px;
        width: 640px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        margin-left: -4px;
        margin-top: -4px;
    }

    .option {
        display: flex;
        width: 290px;
        height: 113.5px;
        //height: 128.5px;
        //height: 92px;
        box-sizing: border-box;

        font-size: 12.5pt;
        //font-size: 14.5pt;
        font-weight: 500;
        padding: 1.0em 38px 1.1em 24px;
        padding-top: .9em;
        padding-right: 20px;
        //padding-top: 0.85em;

        text-align: left;
        //text-align: center;
        line-height: 1.45em;

        color: darken(#0ea5e4, 1%);
        color: darken(#0ea5e4, 4%);
        color: #0ea2df;
        color: #04a8eb;
        //color: #222;
        //color: #135ec4;
        border-radius: 12px;
        border-radius: 12px;
        margin: 8px 8px 4px 0;
        margin: 10px 5px -5px 0;

        background-color: lighten(#d1eff7, 8%); // Was 10% for long time
        background-color: #fff;
        //background-color: transparent;

        //background-color: #04a8eb;
        //box-shadow: 1px 2px 3px 1px lighten(#e3e6f7, 2%);

        transition: 0.2s;

        //background-color: lighten(#04a8eb, 2%);

        //background-color: transparent;

        //height: 153.3px;

        padding: 0 !important;

        .checkmark-icon {
            width: 26px;
            max-width: 20px;
            min-width: 20px;
            margin-left: -26px;
            opacity: 0;
            padding: 0 4px 0 0;
            transition: 0.4s;

            .checkmark-img {
                width: 16px;
            }
        }

        .category-label {
            float: left;
            clear: both;
            font-size: 9.0pt;
            font-weight: 700;
            background-color: lighten(#ffae5e, 5%);
            color: #fff;
            padding: 2px 12px;
            border-radius: 50px;
            margin-left: -8px;
        }

        .label-category {
            display: none;
            font-size: 10.5pt;
            opacity: 0.7;
        }

        .Courage {
            background-color: lighten(#ffae5e, 5%);
        }

        .Grow {
            background-color: lighten(#4bf663, 1%);
        }

        .option-positioning {
            position: relative;
            width: 100%;
            height: 100%;
        }

        .filler {
            position: relative;
            width: 100%;
            height: 100%;
            background-color: #fff;
            border-radius: 12px;

        }

        .filler-flex {
            display: flex;
            width: 100%;
            height: 100%;
        }

        .filler-fill {
            //position: relative;
            align-self: flex-end;
            width: 100%;
            min-width: 100%;
            max-width: 100%;
            height: 0%;
            background-color: lighten(#d7f8ff, 5%);
            //background-color: lighten($positiveAccentColor, 46%);
            border-radius: 12px;

            transition: .7s;
        }

        .label {
            top: -110px;
            height: 110px;
            position: relative;
            font-size: 12.25pt;
            font-size: 11.75pt;
            padding: 1.75em 60px;
            padding: 1.75em 50px;
            padding: 1.75em 30px;
            padding: 1.5em 20px 1.75em 20px;
            //padding-right: 2px;

            padding: 0;
            padding: 1.0em 38px 1.1em 24px;
            padding-top: .9em;
            padding-right: 20px;

            line-height: 1.45em;
            box-sizing: border-box;

            color: darken(#d1eff7, 72%);

            color: #372c49;
            color: #444;
            color: darken(#e75e47, 0%);
            color: #111;
            color: darken(#797dea, 5%);
            //color: darken(#e75e47, 20%);
            font-weight: 500;
            //opacity: 0.5;

            //background-color: #fff;

            //text-align: left;

            color: darken(#04a8eb, 2%);
            color: darken(#04c2eb, 6%);

            //color: darken(#fffdfa, 58%);

            //color: darken(#87d3ff, 28%);

            //color: darken(#1bd0f1, 8%);
            //color: $negativeColor;

            //color: #777;

            transition: .25s;

            text-align: left;
            //text-indent: -18px;
            //padding-left: 38px;
        }

        .label-good-find {
            display: none;
        }

        .icon-img {
            top: -144px;
            left: 250px;
            width: 18px;
            position: relative;
            opacity: 0;
        }
    }

    .option:hover {
        cursor: pointer;
        transform: scale(1.05);
        box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);
        z-index: 2000;
    }

    .option.Filling {
        transition: 1.75s !important;
        transform: scale(1.25) !important;

        .filler-fill {
            height: 100%;
        }
    }

    .option.Selected {
        //opacity: 0;
        background-color: lighten(#d9ebff, 6%);
        box-shadow: none;
        transform: scale(1.00) !important;
        cursor: default !important;

        .label {
            //color: lighten(#04a8eb, 10%);
            opacity: 0.35;
            opacity: 0.25;
            opacity: 0;
        }

        .option-positioning {

            .filler,
            .label {
                opacity: 0 !important;
            }

            .label-good-find {
                //display: block;
            }
        }

    }

    .option.Dropped {
        background-color: transparent !important;

        .checkmark-icon {
            opacity: 1;
        }
    }
}

.label-good-find {
    display: none;
}

.IsStrongPress .label-good-find {
    font-size: 8.75pt;
    //font-family: 'Merriweather';
    font-weight: 700;
    letter-spacing: 1.75px;
    text-transform: uppercase;
    color: darken(#ff8d0d, 4%);
    color: darken($backgroundColor, 20%);
    color: darken(#04c2eb, 4%);
    opacity: .5;

    display: block !important;
    top: -180px;
    position: relative;
    text-align: center;
}

.CardDrop {
    width: 400px;
    height: 332px;
    border-radius: 28px;

    background-color: lighten(#d9efff, 2.5%);
    border: 3px dashed darken(#d9efff, 2%);

    background-color: lighten(#fff4d9, 5.5%);
    border: 3px dashed darken(#fff4d9, 4%);

    box-sizing: border-box;
}


.refresh-container {
    padding-right: 34px;
    transition: .3s all;
    cursor: pointer;
}

.refresh-container:hover {
    transform: scale(1.2);
    margin-top: 8px;
    margin-left: 8px;
}

.ResponseGridPage,
.ThoughtPostRatingPage {

    .chat-label {
        opacity: 0;
    }
}

.NegativeGridPage,
.ThoughtPreRatingPage {
    .chat-label {
        opacity: 1;
    }
}

.IsAnimatingCompletedThought {
    .chat-label {
        opacity: 1;
    }
}



// GREAT JOB ANIMATION
.animation-container {
    position: relative;
    top: 8px;
    left: 48px;
    z-index: 20;

    animation-duration: 1.75s;
    animation-name: slideHand;
    animation-iteration-count: 10;
    animation-direction: normal;
    animation-timing-function: linear;

    padding-top: 6px;
    padding-right: 12px;

    .illustration-img {
        width: 46px;
        align-self: center;

        animation-duration: 0.2s;
        animation-name: wiggle;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-timing-function: linear;
    }
}

.illustration {
    padding-top: 0px;
    animation-duration: 0.26s;
    animation-name: wiggleThinking;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: linear;

    .illustration-img {
        width: 58px;
    }
}

.content-writing {
    .illustration {
        padding-top: 18px;

        .illustration-img {
            width: 52px;
        }
    }

    padding-top: 0px;
}


@keyframes wiggle {
    from {
        transform: rotate(0deg);

    }

    to {
        transform: rotate(2deg);
    }
}

@keyframes wiggleThinking {
    from {
        transform: rotate(0deg);

    }

    to {
        transform: rotate(7deg);
    }
}

@keyframes slideHand {
    from {
        margin-left: 0px;
    }

    to {
        margin-left: 6px;
    }
}

.HideResponseGrid .ResponseGrid {
    opacity: 0;
    margin-left: 40px;
}

.FadeIn {
    animation: contentFadeIn 0.6s ease 0s 1;
}

.FadeInDelay {
    animation-name: contentFadeIn;
    animation-duration: 2s;
    animation-delay: 0s;
    animation-iteration-count: 1;
}

.FadeOut {
    transition: 0.5s all;
}

.FadingOut {
    .FadeOut {
        opacity: 0;
    }
}