@import 'global';

$landingBorderRadius: 32px;

.SessionGrid {
    width: 100%;
    //height: 474px;

    margin: 0px 0 12px 0;


    background-color: lighten(#d9ebff, 0%);
    background-color: lighten(#d1eff7, 9%);
    background-color: #fff;

    background-color: lighten(#d9ebff, 0%);
    //background-color: lighten(#d1eff7, 2%);

    background-color: lighten(#d1eff7, 9%);
    background-color: #fff;
    background-color: transparent;

    //background-color: lighten(#d9ebff, 0%);

    border-radius: $landingBorderRadius;

    transition: .4s;


    //zoom: 1.025;

    .thoughts {
        // zoom: 0.945;
        margin: 20px 14px 0 20px;

        // margin: 14px 20px 4px 12px;
        // padding: 12px;
        // background: darken(#fffdfa, 3%);
    }
}

.SessionThought {
    height: 136px;
    height: 127px;
    height: 154.5px;

    display: flex;
    align-items: center;
    align-content: center;

    border-radius: $landingBorderRadius;
    margin-bottom: 12px;
    margin-top: -5px !important;

    background-color: #fff;
    background-color: lighten(#d1eff7, 9%);
    background-color: lighten(#ffc45e, 30.5%);

    transition: all .3s;
    transition-property: top, height;

    .label {
        width: 120px;
        margin: 0 auto;

        color: lighten(#6A1FE1, 20%);
        //color: #fff;
        font-size: 10.5pt;
        font-weight: 700;
        padding: 0.5em;
        border-radius: 100px;

        font-size: 15pt;
        margin-left: -98px;
        color: lighten(#0ea2df, 30%);
        color: #0ea2df;
        //opacity: .5;
        //opacity: .75;
        //opacity: 0;

        //opacity: .3;
        margin-left: 0px;
        text-align: center;
        width: 100%;
        color: #ffae5e;
        font-size: 21pt;
        font-weight: 400;
        //z-index: -1;

        color: #fbe7d0;

    }

    .thought-content {
        height: 152.5px;
        //height: 118.5px;
        //height: 92px;
        box-sizing: border-box;

        font-size: 13.5pt;
        font-weight: 500;
        padding: 1.1em 68px 1.1em 31px;
        padding-top: 14px;

        text-align: left;
        //text-align: center;

        color: darken(#0ea5e4, 1%);
        color: darken(#0ea5e4, 4%);
        color: #0ea2df;
        color: #04a8eb;
        //color: #222;
        //color: #135ec4;
        border-radius: 12px;
        border-radius: 12px;
        margin: 8px 8px 4px 0;
        margin: 10px 10px -2px 0;
    }

    .thought-label {

        // Was 12.5pt
        font-size: 12.5pt;
        font-size: 14pt;

        padding: 0;

        line-height: 1.40em;
        box-sizing: border-box;

        color: darken(#d1eff7, 72%);

        color: #372c49;
        color: #444;
        color: darken(#e75e47, 0%);
        color: #111;
        color: darken(#797dea, 5%);
        //color: darken(#e75e47, 20%);
        font-weight: 500;
        font-weight: 500;
        //opacity: 0.5;

        //background-color: #fff;

        //text-align: left;
        color: $negativeColor;
    }

    .thought-checks {
        margin-top: auto;
        //margin-left: auto;

        height: 30px;
        //background-color: #ddd;

        display: flex;

        //transform: scale(.8);

        .thought-check {
            width: 16px;
            height: 16px;
            border: 2px solid darken(#ffae5e, 0%);

            //background-color: #e75e47;

            opacity: 0.25;
            border-radius: 100px;
            margin: 0 8px 0 0;

            .checkmark-img {
                opacity: 0;
                width: 10px;
            }
        }

        .thought-check.Completed {
            //@include: #0ea2df;
            //background-color: #e75e47;
            opacity: .75;

            .checkmark-container {
                margin-top: -7.5px;
                margin-left: 3px;
            }

            .checkmark-img {
                opacity: 1;
                padding: 0;
                //margin-top: -40px !important;
            }
        }
    }
}



.SessionThought.Negative {
    //background-color: #fff;

    box-shadow: 1px 2px 3px 1px lighten(#e3e6f7, 0%);

    //border-radius: 18px;

    .thought-checks {
        //margin-left: 260px;

        margin-top: 25px;
        zoom: .7;
        opacity: 0;

        .thought-check {
            border: 3px solid darken(#ffae5e, 0%);
            //opacity: 0.5;
        }
    }
}

.SessionThoughtCompleted {

    //background-color: #fff;

    //box-shadow: 1px 2px 3px 1px lighten(#e3e6f7, 0.5%);

    border-radius: $landingBorderRadius;
    //background-color: #fff;

    background-color: lighten(#ffc45e, 30.5%);
    //background-color: #fff;
    // border: 6px solid lighten(#ffc45e, 30.5%);
    // box-sizing: border-box;
    //border: 6px solid #fff;
    //background-color: #f9f1ff;

    //zoom: .9;

    //background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, #fff 31%, rgba(255, 255, 255, 1) 66%, rgba(255, 255, 255, 1) 100%);


    //border: 8px solid lighten(#ffc45e, 31.5%);
    //border: 8px solid #fff;



    display: block;
    padding: 0px 0 0 0px;

    .original-content,
    .reframed-content {
        display: flex;
        padding-top: 18px;
    }

    .reframed-content {
        padding-top: 14px;
        //padding-bottom: 12px;
        margin-top: -0px;
    }

    .original-content {
        margin-top: -8px;
        padding-top: 0;

        margin-top: 0px;
        padding-left: 0px;
    }

    .checkmark-img {
        width: 14px;
        padding: 8px 8px 0 0;
        opacity: 1;
        align-self: flex-start;
    }

    .x-img {
        opacity: 0.5;
    }

    .label-content {
        width: 340px;
        min-width: 340px;
        padding-right: 24px;

        display: flex;
        //height: 40px;
        align-items: center;
        //justify-content: center;
    }

    .ratings-content {
        display: flex;
        display: none;
        width: 80px;
        min-width: 80px;

        //margin-left: auto;
        padding-right: 30px;
    }

    .rating {
        display: flex;
        //zoom: 1.1;

        .rating-label {
            padding: 0 4px;
            font-size: 10pt;
            font-weight: 700;
            color: $negativeColor;
            opacity: 0.75;
        }

        .rating-label-old {
            opacity: 0.35;
            text-decoration: line-through;
        }

        .rating-label-reframe {
            color: $reinforceColor;
            opacity: 1;
        }

        .rating-bar {

            .bar-fill {}
        }
    }

    .original-rating,
    .reframing-rating {
        margin-top: -4px;
        padding: 16px;
        background-color: lighten($negativeColor, 47%);
        border-radius: 14px;

        //transform: scale(0.9);
    }

    .reframing-rating {
        background-color: lighten(#f76148, 35%);
    }

    .arrow {
        opacity: 0.5;
        margin-top: -2px;
        padding: 0 1px;

        .arrow-img {

            width: 10px;
        }
    }

    .thought-label {
        padding-top: 5px;
        padding-right: 40px;
        font-size: 13.25pt;
        text-align: left;
        opacity: 1;
        color: $negativeColor;
        align-self: flex-start;
    }

    .thought-linethrough {
        text-decoration: line-through !important;
        font-size: 10.0pt !important;
        opacity: .30;

        // background: #797DEA;
        // background: linear-gradient(to right, $negativeColor 0%, lighten($negativeColor, 45%) 33%, $negativeColor 66%, lighten($negativeColor, 45%) 100%);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
    }

    .reframed-label {
        opacity: 1;
        //font-size: 12.50t;
        font-weight: 500 !important;
        color: $reinforceColor;
        text-decoration: none !important;
    }

    .progress-content {
        //margin-top: auto;
        display: flex;

        .progress-label {
            padding: 7px 8px 0 0px;
            font-size: 9pt;
            font-weight: 700;
            color: #0ea2df;
        }
    }

    .checklist {
        //width: 120px;
    }

    .ratings {
        display: flex;
        padding-left: 4px;
        padding-top: 1px;

        .ratings-label {
            padding: 7px 6px 0 78px;
            font-size: 9pt;
            font-weight: 700;
            color: #fff;
        }

        .rating-compare {
            padding: 0 3px;
            font-size: 9pt;
            font-weight: 500;
        }

        .rating-compare-new {
            color: $reinforceColor;
        }

        .rating-compare-old {
            color: #6a1fe5;
            opacity: 0.6;
        }

        .divider {
            width: 30px;
            height: 1.5px;
            background-color: #ffae5e;
            opacity: 0.3;
        }
    }

    .thought-checks {
        margin-top: 8px;
        margin-left: 0px;
        //margin-left: auto;

        height: 30px;
        //background-color: #ddd;

        display: flex;

        //transform: scale(.8);

        .thought-check {
            width: 16px;
            height: 16px;
            border: 2px solid darken(#ffae5e, 0%);

            //background-color: #e75e47;

            opacity: 0.6;
            border-radius: 100px;
            margin: 0 8px 0 0;

            .checkmark-img {
                opacity: 0;
                width: 10px;
                margin: 11px 0 0 -3px;
            }
        }

        .thought-check.Completed {
            //@include: #0ea2df;
            //background-color: #e75e47;
            opacity: 1;

            .checkmark-container {
                margin-top: -7.5px;
                margin-left: 3px;
            }

            .checkmark-img {
                opacity: 1;
                padding: 0;
                //margin-top: -40px !important;
            }
        }
    }
}

.SessionThoughtActive {
    display: block !important;
    box-shadow: none !important;
    background-color: #fffdfa;
    border-radius: $landingBorderRadius;

    //transition: 1s !important;

    .drop-space {
        padding: 9px 9px 10px 10px;
    }

    .drop-card {
        //width: 360px;
        background-color: #fff;
        box-shadow: 0.75px 1.3px 3.5px 0px darken(#eeeae0, 10%);
        border-radius: 14px;

        box-shadow: 0.5px 0.5px 2px 0px lighten(#e9dec5, 0%);
        box-shadow: 0.5px 0.5px 2px 0px #cdb47b; // Also needs to be set in SuggestionsSheet.animateNegativeCard
    }

    .thought-content-active {
        text-align: left;
        padding: 22px 26px;
        width: 277px;
        height: 94px;
    }
}

.SessionThought.Pending,
.SessionThought.IsHidden {
    align-content: center;
    align-items: center;
    justify-content: center;
}

.SessionThoughtExpanded {
    //box-shadow: none !important;
    box-shadow: 0.5px 0.5px 2px 0px #e9dec5;
    align-content: flex-start;
}

.SessionThoughtExpanded.Reinforce {
    //box-shadow: none !important;

    // TODO: Override by JS animation right now.
    box-shadow: 0.5px 0.5px 2px 0px darken(#e9dec5, 6%);
    align-content: flex-start;
}

.SessionThoughtFlat {
    padding: 0;
    margin: 0;
    box-shadow: none;
}

.SessionThought.SlideIn {
    .flat-content {
        position: relative;
        top: 60px;
        opacity: 0;
    }
}

.SessionThought.SlideInNumber {
    .card-number {
        opacity: 0;
        transform: scale(2.2);
    }
}

.SessionGrid.IsPostSession {
    padding-top: 14px !important;
    transform: scale(.925);
    transform-origin: top left;

    .SessionThought {
        background-color: transparent !important;
        margin-top: -42px !important;
        margin-top: -22px !important;

        .flat-content {
            border-radius: 16px !important;
            box-shadow: 1px 1px 4px 1px darken(#e3e6f7, 0%) !important;
            //margin-top: -40px !important;
        }
    }

    // .SessionThought:first-child {
    //     margin-left: 4px;
    // }

    // .SessionThought:nth-child(2) {
    //     margin-left: 14px;
    // }

    // .SessionThought:nth-child(3) {
    //     margin-left: 24px;
    // }
}

@keyframes thoughtSlideIn {
    from {
        top: 60px;
        //transform: scale(1.15);
        //opacity: 0;
        //left: 60px;
    }

    to {
        top: 0px;
        // transform: scale(1);
        //opacity: 1;
        //left: 0px;
        position: static;
    }
}

// Deprectated?
@keyframes numberFadeIn {
    from {
        transform: scale(2.3);
        opacity: 0;
    }

    to {
        transform: scale(1.3);
        opacity: 1;
    }
}