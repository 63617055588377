@import 'global';

$width: 446px;

.CreateAccountDrawer {
    //display: none;  
    position: fixed;
    width: $width;
    min-width: $width;
    top: 0;
    left: $sidebarWidth + 8px;
    height: 100%;
    background: #fff;
    //box-shadow: 0px 2px 4px 1px #ccc;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    overflow: scroll;

    opacity: 1 !important;
    transition: all .3s;

    background-color: #fff;
    z-index: 1000;

    .big-heading {
        padding: 0.5em;
        padding-top: 0;
        font-size: 31pt;
        font-weight: 700;
        color: #111;
        text-align: center;
    }

    .big-subheading {
        padding: 1em;
        padding-top: 20px;
        font-size: 13pt;
        font-weight: 600;
        color: #111;
        text-align: center;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .heading {
        padding-top: 64px;
        font-size: 17.5pt;
        font-weight: 700;

    }

    .options {
        padding: 22px 0;
    }

    .item {
        text-align: left;
        padding: 16px 32px;
        border-bottom: 1px solid #eee;
        font-size: 15pt;
        color: #333;
    }


    .form-heading {
        padding-top: 72px;
        padding-bottom: 8px;
        //padding-left: 36px;
        font-size: 23pt;
        font-weight: 700 !important;
        //letter-spacing: -0.5px;
        text-align: left;
        color: $negativeColor;
        color: $workColor;
        //color: $reinforceColor;
        color: #333;
        color: $negativeColor;
        color: darken($negativeColor, 8%);
    }

    .form-why {
        margin: -12px 0 0 0;
        padding: 0px 20px 16px 0;
        font-size: 10.5pt;
        line-height: 1.45em;

        font-weight: 500;
        text-align: left;

        display: flex;

        .why-p {
            padding: 20px 0 0 2px;
            color: #777;
            color: lighten(#d1819c, 0%);
            color: lighten($workColor, 8%);
            color: darken($negativeColor, 8%);
            color: #333;
            color: lighten($reinforceColor, 10%);
            //color: #0fcbed;
        }

        .illustration-img {

            width: 60px;
            width: 50px;
            margin: 8px 10px -12px -2px;
            align-self: center;
        }
    }

    .form-subheading {
        padding: 20px 40px 16px 0;
        font-size: 10.5pt;
        line-height: 1.45em;

        font-weight: 700;
        color: $negativeColor;
        color: #333;

        text-align: left;

        .step-phase {
            text-decoration: underline;
        }

        .payment-phase {
            font-weight: 500;
        }
    }

    .email-only {
        padding: 30px 0 0 40px;
        font-weight: 500;
        //text-align: left;
    }

    .form {
        width: 352px;
        margin-left: auto;
        margin-right: auto;

        text-align: left;
    }

    .form-error {
        padding: 20px 40px 0 40px;
        font-weight: 500;
        text-align: center;
        color: red;
    }

    .input-text {
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 12px;
        padding: 1em 1em 1em 1em;
        font-size: 11.5pt;
        color: #666;
        font-weight: 500;
        border: 1.5px solid lighten($positiveAccentColor, 28%);
        border-radius: 3px;
        font-size: 12pt;
        outline: none;
        font-family: 'Mulish';
    }

    .input-text:focus {
        border: 1.5px solid lighten($positiveAccentColor, 24%);
        font-weight: 500;
        color: darken($positiveAccentColor, 2%);
        color: #444;
    }

    .input-text::placeholder {
        color: lighten($positiveAccentColor, 14%);
    }



    .submit-button {
        border: 0;
        width: 103%;
        margin-left: -1.5%;
        margin-top: 2px;
        padding: 18px 0 18px 0;
        font-size: 12.5pt;
        font-weight: 600;
        color: #fff;
        background-color: lighten($positiveAccentColor, 14%);
        background-color: lighten($workColor, 12%);
        background-color: #eee;
        //background-color: lighten($negativeColor, 50%);
        //background-color: lighten($negativeColor, 4%);
        border-radius: 100px;
        text-align: center;
        font-family: 'Mulish';

        background-color: #fff;
        box-sizing: border-box;
        border: 2px solid #eee;
        color: #eee;
        cursor: not-allowed;
    }

    .terms {
        margin-top: auto;
        margin-top: 40px;
        border-top: 1px solid #e3e3e3;
        padding: 16px 0 28px 0;
        font-size: 10pt;
        color: #666;
        letter-spacing: 0px;
        text-align: left;
    }
}

.close-button {
    display: flex;
    padding-top: 8px;

    .icon-img {
        margin-left: auto;
        opacity: 0.2;
        padding: 8px 12px 12px 12px;
        width: 40px;
    }

    .icon-img:hover {
        opacity: 0.7;
    }
}