// purple was 6a1fe5, then 9a19fd, try af19fd again later
// was 9a19fd 8% last
$negativeColor: darken(#9a19fd, 8%);
$negativeColor: darken(#bf19fd, 13%);
$reinforceColor: darken(#ff9642, 13%); // was 11%
$positiveAccentColor: #ffa200;

$workColor: #1797cb;
$personalColor: #2dab74;

$backgroundColor: lighten(#d9ebff, 4%);


//$sidebarWidth: 220px;
$sidebarWidth: 240px;

$sansFamily: "Manrope",
ui-sans-serif,
-apple-system,
BlinkMacSystemFont,
"Segoe UI",
Helvetica,
"Apple Color Emoji",
Arial,
sans-serif,
"Segoe UI Emoji",
"Segoe UI Symbol";