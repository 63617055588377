@import 'global';

#fontSizeTester {
    position: absolute;
    visibility: hidden;
    height: auto;
    width: auto;
    white-space: nowrap;
}

.good-phrase span span {
    position: relative;
    opacity: 0;
    animation: move-text 0.1s forwards;
}

.ai-slides-position {
    position: relative;
}

.ai-slides img {
    position: absolute;
    top: 0;
    left: 0;
    width: 400px;
    height: 328px;
    border-radius: 28px;
    -webkit-animation-name: fade;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 1s;
    // animation-name: fade;
    // animation-iteration-count: infinite;
    // animation-duration: 6s;
}

.slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 400px;
    height: 328px;
    border-radius: 28px;
    background-size: 628px;
    background-position: top center;
    background-repeat: no-repeat;

    -webkit-animation-name: fade;
    //-webkit-animation-iteration-count: 1;
    -webkit-animation-duration: 1s;
    animation-name: fade;
    //animation-iteration-count: 1;
    animation-duration: .5s;
    transition: opacity .4s;
}

@-webkit-keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1 !important;
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1 !important;
    }
}

#slidef1 {
    //background-color: lightblue;
    top: 0px;
    opacity: 0.5;
}

#slidef2 {
    //-webkit-animation-delay: 2s;
    //background-color: yellow;
    top: 0px;
    opacity: 0;
}

#slidef3 {
    //-webkit-animation-delay: 4s;
    //background-color: lightgreen;
    top: 0px;
    opacity: 0;
}

#slidef4 {
    //-webkit-animation-delay: 4s;
    //background-color: lightgreen;
    top: 0px;
    opacity: 0;
}

.ai-card {
    width: 400px;
    height: 328px;
    background-color: #fff;
    border-radius: 28px;
}

.ai-message {
    position: absolute;
    top: 40px;
    left: 40px;
    opacity: 1;

    padding-top: 0.5em;


    //background-color: #fff;
    padding: 20px;
    width: 340px;
    margin: 0 auto;
    margin-top: 170px;
    border-radius: 20px;
    //opacity: 0.8;
    zoom: 0.85;

    line-height: 1.4em;

    font-size: 16.5pt;
    font-weight: 600;
    color: darken(#fff, 0%);
    text-shadow: 2px 2px 2px rgba(34, 34, 34, 0.56);

    opacity: 0;
    margin-top: 220px;

    transition: all 0.5s;
}

.ai-tip.Collapsed {
    opacity: 0;
    transform: scale(0.35);
    margin-left: -102px;
    padding-top: 122px;
    box-shadow: none !important;
    // margin-top: 300px;
    // margin-left: 246px;
    transition: 0.6s all;

    .ai-message {
        opacity: 0 !important;
    }
}

.strike {
    margin-left: -3px;
    background-color: $negativeColor;
    height: 2.0px;

    transition: 0.35s;

    opacity: .7;
}

.strike-line1-single {
    margin-top: -12px;
}

.strike-line1 {
    margin-top: -38.5px;
}

.strike-line2 {
    margin-top: 24.5px;
}

.ThoughtGrid2 {
    //opacity: 0;

    zoom: 0.94;
    zoom: 0.98;

    margin: 0px auto;

    padding: 60px 0 0 0;

    .ai-tip {
        width: 400px;
        height: 328px;
        background-color: #fff;
        border-radius: 28px;
        background-size: 578px;
        background-position: top center;
        background-repeat: no-repeat;

        box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);



        // background-size: 280px;
        // background-size: 420px;

        .sheet-content {
            padding-top: 40px;
            padding-bottom: 20px;
        }


        .face-img {
            opacity: 0;
            width: 56px;
        }

        .julia-heading {
            //border-top: 2px solid #fafafa;
            padding-top: 1.5em;
            font-size: 14pt;
            font-weight: 700;
            color: darken(#0ea5e4, 1%);


            padding-top: 0.5em;
            font-size: 11.5pt;
        }

        .julia-subheading {
            padding-top: 0.5em;
            font-size: 11.5pt;
            font-weight: 600;
            color: darken(#0ea5e4, 1%);

            background-color: #fff;
            padding: 20px;
            width: 340px;
            margin: 0 auto;
            margin-top: 100px;
            border-radius: 20px;
            //opacity: 0.8;
            zoom: 0.85;

            font-size: 15pt;
            font-weight: 700;
            line-height: 1.4em;
            //color: #fff;
            //text-shadow: 3px 3px 2px rgba(34, 34, 34, 0.26);

            //opacity: 0;

            opacity: 0;
        }
    }

    .main-heading {
        display: flex;

        width: 100%;
        margin: 0px auto;

        //align-items: center;
        justify-content: center;

        padding-top: 30px;
        text-align: left;

        .heading-prompt {
            margin-left: 10%;
            width: 90%;
            text-align: left;
            font-size: 19pt;
            font-weight: 600;

            color: #0ea2df;
        }
    }

    .main-layout {
        display: flex;

        width: 1280px;
        max-width: 1280px;
        min-width: 1280px;
        margin: 0px auto;
        //margin-left: 264px;
        padding-left: 272px;
        padding-left: $sidebarWidth + 42px; // Was 42px non-responsive
        // 27px seems good for 1360px width screens or so

        //align-items: center;
        //justify-content: center;
        justify-content: flex-start;
        justify-items: flex-start;
        align-content: flex-start;
        align-items: flex-start;

        padding-top: 24px;
        padding-top: 44px;
        padding-top: 44px;
        //zoom: 0.975;


        .buffer-col {
            width: 10%;
            max-width: 10%;
            //max-width: 400px;
            flex: 1;

            display: none;
        }

        .custom-col {
            //width: 520px;
            min-width: 420px;
            max-width: 420px;
            min-width: 416px;
            max-width: 416px;

            min-width: 398px;
            max-width: 398px;

            min-width: 394px;
            max-width: 394px;

            // min-width: 374px;
            // max-width: 374px;

            padding-bottom: 102px;

            // padding-top: 200px;
        }

        .suggestions-col {
            flex: 1;
            width: 860px;
            min-width: 860px;
            max-width: 860px;

            // min-width: 870px;
            // max-width: 870px;

            zoom: 1.05;
        }

        .left-col {
            width: 64%;
            border-right: 0px solid darken(#e3e6f7, 2%);
            height: 2000px;
            min-width: 780px;
            max-width: 780px;
            width: 780px;
            flex: 1;
        }

        .right-col {
            width: 25%;
            min-width: 410px;
            max-width: 440px;
            flex: 1;

            display: none;

            box-sizing: border-box;
            padding-left: 18px;
            padding-right: 20px;

            text-align: left;
        }
    }

    .suggestions-content {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        padding: 10px 20px 4px 20px;

        background-color: #fff;
        //background-color: lighten(#d9ebff, 6%);
        border-radius: 24px;
        border-radius: 28px 28px 0 0;


        padding: 0;
        padding-left: 4px;
        background-color: transparent;

        .group-col {
            width: 33%;
        }

        .reframing-group-col {
            width: 34%;
        }
    }


    .section-heading {
        font-size: 11pt;
        font-weight: 700;
        color: #111;
        color: darken(#0ea5e4, 4%);
        //color: #677583;
        padding-top: 26px;

        padding-bottom: 12px;
        padding-left: 20px;
        text-align: left;
    }

    .heading {
        color: darken(#ffc27d, 16%);
        //color: darken($negativeColor, 10%);

        //color: $reinforceColor;
        font-size: 11.5pt;
        font-weight: 600;
        //font-family: Georgia, 'Times New Roman', Times, serif;
        font-family: 'Merriweather', serif !important;
        font-weight: 600;
        text-align: center;
        width: 240px;
        margin: 0 auto;
        margin-left: 76px;
        background-color: #fff;
        border-radius: 24px 24px 0 0;
        padding: 0px 12px 10px 40px;

        margin-bottom: -24px;

        background-color: transparent;
        margin-left: -55px;

        font-size: 22.0pt;
        font-size: 26.5pt;
        margin-top: 2.5px !important;
        margin-bottom: -21px !important;
        margin-left: -10px;
        letter-spacing: -.25px;
        //font-weight: 700;

        margin-top: 3.5px !important;
        font-size: 26.0pt;

        // position: relative;
        // top: -40px;
        // left: -70px;

        //font-size: 28pt;

        // background: #ffbf6e;
        // background: linear-gradient(110deg, #ffbf6e 60%, #FF9C2B 100%);
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;

    }

    .reframing-content {
        display: none;

        .options {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;

            padding: 0 20px 32px 20px;
            padding-bottom: 44px;

            background-color: transparent;
            padding: 0;
        }

        .option {

            width: 47.5%;
            height: 84px;
            box-sizing: border-box;

            font-size: 12.5pt;
            font-weight: 500;
            padding: 1em 28px;

            text-align: left;
            //text-align: center;
            line-height: 1.45em;

            color: darken(#0ea5e4, 1%);
            color: darken(#0ea5e4, 4%);
            color: #0ea2df;
            color: #04a8eb;
            //color: #222;
            //color: #135ec4;
            border-radius: 12px;
            border-radius: 20px;
            margin: 8px 8px 4px 0;
            margin: 10px 10px -2px 0;

            background-color: lighten(#d1eff7, 11%); // Was 10% for long time
            background-color: #fff;
            //background-color: #fff;
            //box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);

            transition: 0.25s;

            .label {

                font-size: 12.5pt;
                padding: 1.75em 60px;
                padding: 1.75em 50px;
                padding: 1.75em 30px;
                padding: 1.5em 20px 1.75em 20px;
                padding-right: 12px;

                padding: 0;

                line-height: 1.4em;
                box-sizing: border-box;

                color: darken(#d1eff7, 72%);

                color: #372c49;
                color: #444;
                color: darken(#e75e47, 0%);
                color: #111;
                color: darken(#797dea, 5%);
                //color: darken(#e75e47, 20%);
                font-weight: 500;
                //opacity: 0.5;

                //background-color: #fff;

                //text-align: left;

                color: darken(#e75e47, 0%);

                transition: .25s;

                text-align: left;
                //text-indent: -18px;
                //padding-left: 38px;
            }
        }

        .option:hover {
            cursor: pointer;
            //transform: scale(1.1);
        }

        .option.Reinforce {
            .label {
                background: #ee9b69;
                background: linear-gradient(to right, #ee9b69 0%, #ee9b69 40%, #e75e47 80%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            //font-size: 13.pt;
            //opacity: 0.9;
        }
    }

    .options-heading {
        border-bottom: 0px solid darken(#e3e6f7, 0%);
        font-size: 11pt;
        font-weight: 700;
        color: #111;
        color: darken(#0ea5e4, 4%);
        color: #677583;
        padding-top: 30px;
        //margin-left: 190px;
        padding-left: 24px;
        margin-right: 60px;
        padding-bottom: 4px;

        text-align: left;
    }

    .options-heading-right {
        border-bottom: 0px solid darken(#e3e6f7, 0%);
        font-size: 11pt;
        font-weight: 700;
        color: #111;
        color: darken(#0ea5e4, 4%);
        color: #677583;
        padding-top: 30px;
        //margin-left: 210px;
        //padding-left: 24px;
        margin-right: 60px;
        padding-bottom: 12px;

        text-align: left;
    }

    .refresh-container {
        display: none;
        padding: 0 20px 0 0px;
    }

    .refresh-icon-img {
        width: 34px;
    }

    .refresh-button {
        color: darken(#0ea5e4, 2%);
        //color: #222;
        //color: #135ec4;
        border-radius: 24px;
        //margin: 8px 8px 4px 0;

        width: 160px;
        margin: 0px auto;
        //float: right;

        padding: 16px 0;

        //background-color: lighten(#d1eff7, 10%);
        //box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);
    }

    .categories {
        display: flex;
        display: none;
        zoom: .925;
        justify-content: center;
        margin-right: 36px;
    }

    .category {
        border: 2px solid #fff;
        background-color: #fff;
        border-radius: 100px;
        font-size: 10.5pt;
        font-weight: 500;
        padding: 1em 2em;
        margin: 12px 6px;
    }

    .category.Selected {
        font-weight: 700;
    }


    .category-headings {
        display: flex;
        width: 100%;

        .category-col {
            width: 49%;
            min-width: 49%;
        }




    }

    .category-heading {
        float: left;
        margin: 12px 0 0 10px;
        //margin: 12px auto;
        padding: 0.7em 1.5em;
        background-color: #eee;

        font-size: 10pt;
        font-weight: 800;
        border-radius: 100px;

        background-color: #e2e3ff;
        color: lighten(#4447A1, 10%);

        background-color: lighten(#d7f3ff, 2%);
        color: darken(#29b0e7, 10%);

        // background-color: lighten(#f5ebd1, 7%);
        // color: darken(#f2c353, 20%);
        //color: #fff;
    }


    .category-heading-personal {
        background-color: lighten(#d9f9e7, 2%);
        color: darken(#3bcb7b, 10%);


    }

    .category-heading-reframed {
        margin-left: 0px;
        background-color: lighten(#ef6834, 8%);
        color: #fff;
        zoom: 0.9;
    }
}

.loading-placeholders {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    padding: 0;
}

.loading-placeholder {
    height: 78px;
    opacity: 0.3;
    margin-left: -30px;
}

::placeholder {
    /* Most modern browsers support this now. */
    color: lighten(#6368e7, 30%);
    color: #d1ebf5;
    color: lighten(#d9ebff, 0%);
    //color: #ccc;
}

.loading-position {
    position: relative;
    opacity: 0;

    .loading-box {
        animation: .8s infinite alternate inhale;
        position: absolute;
        top: -380px;
        left: 302px;

        width: 240px;

        padding: 28px 0 12px 0;

        background-color: #fff;
        box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);
        border-radius: 40px;

        zoom: 1.05;
    }

    .chatbot-img {
        display: none;
        width: 32px;
    }

    .label {
        padding: 12px 0;
        color: #797DEA;
        font-size: 10pt;
        line-height: 1.4em;
    }

}

@keyframes inhale {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1.08);
    }
}


@media (min-width: 1840px) {
    body {
        zoom: 1.1;
    }
}

@media (max-width: 1540px) {
    body {
        zoom: 0.975;
    }
}

@media (max-width: 1460px) {
    body {
        zoom: 0.95;
    }

    .sidebar {
        //display: none !important;
    }
}

@media (max-width: 1380px) {
    body {
        zoom: 0.90;
    }

    .sidebar {
        //display: none !important;
    }
}


.NegativeThoughtCard {
    margin-top: 20px;
    margin-bottom: 14px;

    width: 374px;
    height: 154px;

    padding: 20px 30px;
    padding: 8px 0px;

    border: 0;
    border-radius: 18px;

    font-size: 15pt;

    background-color: #fff;
    background-color: lighten(#ffc45e, 30.5%);
    //background-color: #fffdfa !important;
    outline: none;

    font-size: 14.5pt;
    font-weight: 700;

    text-align: center;

    color: darken(#797DEA, 5%);

    box-sizing: border-box;


    transition: 0.3em;

    .flat-meter-container {
        padding: 4px 0 0 0;
        margin-left: -4px;
    }

    .original-thought {

        padding: 0px 68px 0 0px;
        padding: 0px 68px 7.8px 26px;
        text-align: left;


        .label {
            padding: 0;
            text-align: left;
            color: $negativeColor;
            font-weight: 500;
        }
    }

    .label {

        //transform: scale(1.3);
        padding: 4px 54px 0 40px;

        // Was 12.5pt
        font-size: 15.5pt;


        box-sizing: border-box;

        line-height: 1.4em;
        box-sizing: border-box;
        //color: #777;

        transition: .25s;

        text-align: left;
        //text-indent: -18px;
        //padding-left: 38px;
    }

    .done-button-container {
        position: relative;
        z-index: 1000;
        width: 0;
        height: 0;

        .d-button {
            position: absolute;
            z-index: 1000;
            top: 182px;
            left: 10px;
            width: 300px;

            background-color: #ffae5e;
            border-radius: 80px;
            //background: linear-gradient(to right, darken(#6a1fe5, 0%) 30%, #6a1fe5 100%);
            background-color: #fff;
            color: #ffbd5e;
            padding: 22px 0px;
            font-size: 16.5pt;

            box-shadow: 1px 2px 8px 1px darken(#e3e6f7, 6%);
            box-shadow: 1.5px 1.5px 8px 0px darken(#e9dec5, 0%);
            box-shadow: 0.5px 0.5px 2px 0px darken(#e9dec5, 6%);
            transition: 0.3s;

            top: 162px;
            left: 54px;
            width: 240px;
            padding: 30px 0;

            display: flex;
            align-items: center;
            justify-content: center;
            align-content: center;

            .checkmark-img {
                width: 20px;
                padding: 0 6px 0 0;
                align-self: center;
            }

            .checkmark-label {
                color: #ff944d;
            }
        }

        .d-button:hover {
            top: 152px;
            background-color: lighten(#ffb95e, 2%);
            background: linear-gradient(to right, darken(#878bec, 1%) 30%, lighten(#4447A1, 4%) 100%);
            cursor: pointer;
        }

        .d-button.Hidden {
            opacity: 0;
            left: 156px;
        }

        .d-button.Leave {
            opacity: 0 !important;
            left: -100px !important;
        }
    }
}

.NegativeThoughtCard.Expanded {
    margin-top: -90px;
    height: 561px;

    //box-shadow: ;
    //box-shadow: 1px 2px 3px 1px lighten(#e3e6f7, 0%);
}

.NegativeThoughtCard-container.AnimatedFloat-container {
    position: relative;
}

.NegativeThoughtCard.AnimatedFloat {
    position: absolute;
    //width: 400px !important;
    top: -486px;
}

.NegativeThoughtCard.AnimatedFloatFade {
    top: -436px;
    opacity: 0;
    transition: .3s;
}

.custom-thought-container {
    display: flex;
    margin-top: 20px;
    margin-bottom: 14px;

    width: 340px;
    min-width: 340px;
    border: 0;
    border-radius: 28px;

    padding: 1em 2em;
    padding-bottom: 1.25em;

    padding: 0;
    font-size: 15pt;

    background-color: #fff;
    outline: none;

    font-size: 14.5pt;
    font-weight: 700;

    text-align: center;

    color: darken(#797DEA, 5%);

    font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
    font-family: "Manrope";


    .compose-icon {
        background-color: lighten(#0ea2df, 15%);
        background-color: #fff;
        //background-color: #fff;;
        border-radius: 100px;
        max-width: 48px;
        min-width: 48px;
        height: 48px;

        margin: 10px 0 0 16px;
        box-sizing: border-box;

        //opacity: 0;

        .icon-img {
            margin-top: 10px;
            width: 24px;
        }
    }

}

.CustomThought {
    width: 260px;
    height: 110px;
    border: 0;
    border-radius: 28px;

    padding: 1em 2em;
    padding-bottom: 1.25em;

    //padding: 0;
    font-size: 15pt;

    outline: none;

    font-size: 14.5pt;
    font-weight: 700;

    text-align: center;

    color: darken(#797DEA, 5%);

    font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
    font-family: "Manrope";

    //box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);
}

.heading-icon-img {
    width: 34px;
    margin-top: -4px;
    margin-bottom: 2px;
}

.brain-icon-img {
    width: 37px;
}

.chat-icon-img {
    display: none;
    width: 22px !important;
    padding: 4px;
}

.heading-chat {
    margin-top: 12px !important;
    margin-bottom: -18px !important;
    display: flex;
    justify-content: center;
}

.heading-label {
    padding-bottom: 6px;
}

.options {
    .heading-position {
        position: absolute;

        height: 0;
    }

    .heading {
        position: relative;
        left: -204px;
        top: 38px;
        top: 128px;
        top: 18px;
        width: 160px;
        //height: 48px;
        padding-top: 20px;
        //background-color: transparent;
        border-radius: 28px 0 0 28px;

        background-color: lighten(#d9ebff, 6%);
        line-height: 1.3em;
        font-weight: 600;
        font-size: 10.5pt;
    }
}

.ThoughtGrid2 .reframing-content {
    //display: none;
    padding-top: 0px;
    margin-top: -30px;

    //border-top: 2px solid lighten(#d9ebff, 0%);

    .heading-position {
        position: absolute;

        height: 0;
    }

    .heading {
        position: relative;
        left: -184px;
        top: 38px;
        top: 28px;
        top: 12px;
        width: 160px;
        //height: 48px;
        padding-top: 20px;
        //background-color: transparent;
        border-radius: 28px 0 0 28px;

        background-color: lighten(#d9ebff, 6%);
        //background-color: lighten(#e6b812, 48%);
        line-height: 1.3em;
        font-weight: 600;
        font-size: 10.5pt;
    }

    .reframing-sheet {
        //background-color: #fff;
        background-color: lighten(#d9ebff, 6%);
        background-color: #fff;
        //background-color: lighten(#e6b812, 50%);
        //background-color: lighten(#e6b812, 50%);
        border-radius: 28px;
        border-radius: 0 0 28px 28px;
        //border-radius: 28px;
        margin: 22px 0 0 0;

        padding: 14px 0 36px 0;

        min-height: 140px;


        background-color: transparent;
        padding: 0;
    }

    .options {
        //padding: 0 0 20px 0;
        padding-top: 0;
        padding-bottom: 20px;
        //background-color: #fff;
        border-radius: 28px;
        //background-color: lighten(#d9ebff, 6%);
        //background-color: lighten(#e6b812, 50%);
    }
}


.suggestions-sheet {
    display: none !important;
}

.keep-going-message-container {
    position: fixed;
    left: 190px;
    bottom: 220px;
}


.session-content {
    border-top: 0px solid lighten(#d9ebff, 4%);
    margin-top: 8px;
    padding-top: 6px;


    background-color: #fff;
    background-color: transparent;
    padding: 16px 18px;
    width: 200px;
    margin: 0 auto;
    margin-top: -66px;
    border-radius: 20px;
    //opacity: 0.8;

    display: flex;

    //box-shadow: 1px 2px 3px 1px darken(#e3e6f7, 0%);
}

// SEssionCard paste



.response {
    display: flex;
    align-items: flex-start;
    zoom: 0.75;
    padding: 4px 32px 6px 24px;
    border-bottom: 0px solid lighten(#04a8eb, 49%);

    .response-checkmark {
        width: 18px;
        //align-self: center;

        margin: 8px 6px 0 0;
    }

    .response-content {
        display: flex;
        //height: 66px;
    }

    .response-number {
        width: 28px;
        min-width: 28px;
        max-width: 28px;
        height: 28px;
        box-sizing: border-box;
        border-radius: 14px;
        margin: 2px 2px 0 0;
        background-color: lighten(#04bee6, 3%);
        //background-color: lighten(#ffc274, 21%);
        color: lighten(#04bee6, 10%);
        color: #fff;

        padding-top: 3px;
        font-size: 12pt;
        font-weight: 600;

        transform: scale(.95);

        background-color: transparent;
        color: darken(#04c2eb, 5%);
        color: lighten($positiveAccentColor, 20%);
        font-weight: 700;
        font-size: 13pt;
        //font-family: 'Merriweather';
        font-weight: 700;
        transform: scale(1.1);
        margin: 2px 6px 0 -12px;

        //display: none;
    }

    .response-icon2 {
        width: 28px;
        min-width: 28px;
        max-width: 28px;
        height: 28px;
        box-sizing: border-box;
        border-radius: 14px;
        margin: 3px 12px 0 -4px;
        background-color: #fff !important;
        box-shadow: 0.5px 0.5px 2px 0px #cdb47b;
        //border: 1px solid lighten(#cdb47b, 32%);

        transform: scale(1.3);
    }

    .response-area-img {
        width: 16px;
        align-self: flex-start;
        //align-self: flex-start;
        margin: 7.5px 6px 0 -6px;

        margin: 5px 3px 0 2px;
    }

    .response-area-ease {
        width: 14.5px;
        margin-top: 6px;
        margin-left: 3.0px;
    }

    .response-area-openness {
        width: 17px;
        margin: 6px 3px 0 3px;
        opacity: .8;
    }

    .response-label {
        //float: left;
        font-size: 15.5pt;

        padding: 0.2em 0px;
        padding-left: 0px;
        padding-top: 0;
        text-align: left;

        line-height: 1.5em;
        font-weight: 500;

        color: darken(#04d8eb, 6%);
        color: darken(#04c2eb, 5%);
    }
}

.response.IsStrongPress {
    .response-label {
        text-decoration: underline;
        text-decoration-color: lighten(#04c2eb, 42%);
        text-decoration-color: lighten($reinforceColor, 32%);
        text-decoration-color: lighten($positiveAccentColor, 32%);
        /* Set underline color */
        text-decoration-thickness: 2.5px;
        /* Set underline width */
    }
}

.response {
    .label {}
}

.response-heading {
    //display: none;
    padding: 4px 0;
    margin-top: -2px;
    padding-top: 0;
    font-size: 10.25pt;
    color: darken(#04d8eb, 5%);
    color: #111;
    color: darken(#fffdfa, 50%);
    color: #f88300;
    //opacity: 0.25;
    font-weight: 700;
    text-align: left;
    padding-left: 28px;
    padding-right: 0px;
}

$start: .85;
$multiplier: .02;
$moveMultiplier: 0px;

// .response-group.Length0 {
//     transform: scale($start + ($multiplier * 1));
//     margin-top: $moveMultiplier * 1;
// }

// .response-group.Length1 {
//     transform: scale($start + ($multiplier * 2));
//     margin-top: $moveMultiplier * 2;
// }

// .response-group.Length2 {
//     transform: scale($start + ($multiplier * 3));
//     margin-top: $moveMultiplier * 3;
// }

// .response-group.Length3 {
//     transform: scale($start + ($multiplier * 4));
//     margin-top: $moveMultiplier * 4;
// }

// .response-group.Length4 {
//     transform: scale($start + ($multiplier * 5));
//     margin-top: $moveMultiplier * 5;
//     //box-shadow: 0.5px 0.5px 4px 0px #e9dec5;
// }

// .response-group.Length5 {
//     transform: scale($start + ($multiplier * 6));
//     margin-top: $moveMultiplier * 6;
//     //box-shadow: 0.5px 0.5px 6px 0px #e9dec5;
// }

// .response-group.Length6 {
//     transform: scale($start + ($multiplier * 7));
//     margin-top: $moveMultiplier * 7;
//     //box-shadow: 0.5px 0.5px 8px 0px #e9dec5;
// }

.response-group {

    transition: .2s;

    padding-top: 8px;

    border-bottom: 0px solid lighten(#04a8eb, 49%);

    //background: #fff;
    border-radius: 12px;
    //box-shadow: 0.5px 0.5px 2px 0px #e9dec5;


    margin: -8px 12px 0 6px;
    height: 352px;

    transform: scale(0.96);


    // background-color: #fff;

    // box-shadow: 0.5px 0.5px 2px 0px #e9dec5;
    // border-radius: 12px;
    // border-radius: 2px;
}

.completed-message-container {
    position: absolute;
}

.CompletedMessage {
    position: relative;

    left: 382px;
    width: 190px;
    height: 150px;
    box-sizing: border-box;

    background-color: lighten(#29b0e7, 0%);
    //background-color: #fff;
    border-radius: 32px;
    z-index: 1000;

    transition: .3s;

    box-shadow: 0.5px 2px 2px 1px darken(#e3e6f7, 0%);

    background-color: #fff;

    transform: scale(1.025);

    pointer-events: none;

    .content {
        //display: flex;

        .icon {
            padding-top: 26px;
            opacity: 1 !important;
        }

        .icon-img {
            width: 66px;
        }

        .label {
            padding-top: 4px;
            color: #fff;
            font-weight: 700;
            font-size: 13pt;

            color: $positiveAccentColor;
        }

        .label-no-video {
            margin-top: -16px;
        }

        .animated-icon {
            padding: 22px 0 0 0;
        }

        .animated-icon-pencil {
            padding: 22px 0 0 18px;
        }
    }

    .bounce-label {
        animation: messageBounce 1.05s 1 0.3s;
        //messageOneBounce .3s 1 1.05s;
        animation-timing-function: ease-in-out;
        // position: relative;
        // top: 0;
        // left: 0;
    }
}

.CompletedMessage.Slot1 {
    top: -542px;
    top: -490px;
}

.CompletedMessage.Slot2 {
    top: -414px;
    top: -326px;
}

.CompletedMessage.Slot3 {
    top: -254px;
    top: -165px;
}

.CompletedMessage.Slot4 {
    top: -345px;
    left: 96px !important;
}

.CompletedMessage.Slot4.Hide {
    top: -345px;
    left: 180px !important;
}

.CompletedMessage.Slot0 {
    //display: none;
    pointer-events: none;
}


.CompletedMessage.Show {
    opacity: 1;
}

.CompletedMessage.Hide {
    opacity: 0;
    left: 450px; // was like 380px before Slot4 experiment
}

.NegativeCardContentPlaceholder {
    height: 162px;
}

.ReinforceCardContentPlaceholder {
    height: 172px;

    .original-content,
    .reframed-content {
        display: flex;
        padding-top: 0px;
    }

    .reframed-content {
        padding-top: 0px;
        margin-top: -0px;
    }

    .original-content {
        margin-top: -8px;
        padding-top: 0;
    }

    .checkmark-img {
        width: 14px;
        padding: 8px 8px 0 0;
        opacity: 1;
        align-self: flex-start;
    }

    .thought-label {
        font-weight: 500;
        padding-top: 5px;
        font-size: 11.5pt;
        text-align: left;
        opacity: 1;
        color: $negativeColor;
        align-self: flex-start;
    }

    .thought-linethrough {
        text-decoration: line-through !important;
        //color: lighten(#6a1fe5, 32%);
        opacity: 0.5;
        //opacity: 1;
    }


    .reframed-label {

        // Was 12.5pt
        font-size: 12.5pt;
        font-size: 14pt;
        font-weight: 500;

        padding: 0;

        line-height: 1.35em;
        box-sizing: border-box;
    }

    .reframed-label {
        opacity: 1;
        //font-size: 12.50t;
        color: $reinforceColor;
        text-decoration: none !important;
    }
}

.my-node-enter {
    opacity: 0;
    margin-left: 80px;
}

.my-node-enter-active {
    opacity: 1;
    transition: 320ms;
    margin-left: 0px;
}

.my-node-exit {
    opacity: 1;
    margin-left: 0px;
}

.my-node-exit-active {
    opacity: 0;
    transition: 320ms;
    margin-left: 80px;
}

$leftPadding: 18px;
$borderColor: #fff;

.sidebar {
    .sidebar-heading222 {
        padding: 0px 0 0 18px;
        font-size: 12.0pt;
        //font-family: 'Merriweather';
        font-weight: 700;
        color: darken(#ff8d0d, 4%);
        //color: $negativeColor;
        //color: darken(#deeeff, 14%);
        text-align: left;

        padding: 0px 0 0 18px;
        font-size: 8.5pt;
        font-family: 'Merriweather';
        font-weight: 500;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: darken(#ff8d0d, 4%);
        color: darken($backgroundColor, 22%);
        //color: $negativeColor;
        //color: darken(#deeeff, 14%);
        text-align: left;
    }

    .sidebar-heading,
    .sidebar-subheading {
        padding: 0px 0 0 18px;
        font-size: 8.5pt;
        //font-family: 'Merriweather';
        font-weight: 700;
        letter-spacing: 1.75px;
        text-transform: uppercase;
        color: darken(#ff8d0d, 4%);
        color: darken($backgroundColor, 20%);
        //color: $negativeColor;
        //color: darken(#deeeff, 14%);
        text-align: left;
    }
}


.BioList {
    margin: 0 4px;
    margin-top: 76px;
    margin-top: 92px;
    margin-top: 30px;
    //margin-top: 22px;
    padding-bottom: 28px;
}

.BioItem {
    padding: 7px 8px 0px 18px;

    .label {
        padding-top: 1px;
        padding-left: 14px;
        font-size: 9.75pt;
        font-weight: 700;
        color: darken(#ff8d0d, 10%);
        color: darken(#ffa542, 2%);
        color: lighten(#1797cb, 10%);
        text-align: left;
    }

    .content {
        padding-left: 22px;
        font-size: 9.5pt;
        font-weight: 500;
        color: darken(#ccc, 2%);
        color: darken(#ffa542, 4%);
        color: lighten(#ffa542, 14%);
        text-align: left;

        width: 148px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.BioItem:hover {
    .label {
        text-decoration: underline;
    }

    .content {
        text-decoration: underline;
        color: lighten(#ffa542, 4%) !important;
    }

    cursor: pointer;
}

.BioItem.WorkItem {
    .label {
        color: lighten($negativeColor, 34%);
    }

    .content {}
}

.BioItem.PersonalItem {
    .label {
        color: lighten(#2dab74, 18%);
    }

    .content {}
}

.CoachSection {
    .unlock-instructions {
        padding: 0 0 0 2px;
        color: lighten(#ffc789, 0%);
        font-size: 9.5pt;
        font-weight: 500;
    }
}

.CoachCard {
    display: flex;
    padding: 8px 12px 6px 32px;

    align-items: center;

    .coach-profile-img {
        width: 22px;
        border-radius: 100px;
        align-self: center;
        opacity: 0.8;
    }

    .name-label {
        padding: 0 0 0 8px;
        font-size: 9.25pt;
        font-weight: 700;
        color: lighten($positiveAccentColor, 14%);
    }
}

.AccountSettings {
    //margin: 0 8px;
    padding: 4px 8px 0 8px;
    margin-top: auto;
    margin-bottom: 18px;

    height: 34px;
    min-height: 34px;
    max-height: 34px;

    border-top: 0px solid lighten($backgroundColor, 1%);
    border-radius: 0;
    ;

    // background-color: darken(#fffdfa, 1%);
    // border: 2px solid darken(#fffdfa, 6%);
    //border-radius: 12px;


    .account-heading {
        display: flex;
        padding: 10px 0 6px 18px;

        .icon-img {
            width: 14px;
            align-self: center;
            margin-right: 6px;
        }

        .label {
            // text-transform: uppercase;
            // letter-spacing: 1px;
            color: #444;
            color: darken(#ffa542, 8%);
            font-weight: 700;
            font-size: 10.5pt;
            text-align: left;
        }
    }

    .status-explanation {
        padding: 0 28px 0 32px;
        font-size: 9.5pt;
        font-weight: 500;
        color: #444;
        color: darken(#ffa542, 8%);
        opacity: .7;
        //color: #bbb;
        text-align: left;
    }
}

.page-greeting {
    position: fixed;
    top: 0px;
    left: $sidebarWidth + 2px;



    //background-color: #fff;
    border-radius: 12px;
    width: 280px;
    padding: 12px 18px;


    padding-left: 19px;


    //color: $positiveAccentColor;

    background-color: lighten(#fcf6ed, 2.5%);
    //background: linear-gradient(0deg, lighten(#fcf6ed, 2%) 0%, lighten(#fcf6ed, 4%) 100%) no-repeat;

    //background-color: transparent;
    border-bottom: 1.5px solid darken(#fcf6ed, 6%);

    border-radius: 0;
    width: 100%;

    text-align: left;
    letter-spacing: 0 !important;

    //zoom: 1.05;


    display: flex;
    //justify-content: center;
    align-content: center;
    align-items: center;

    //opacity: 0;

    //padding-left: 20px;

    .session-number {
        background-color: #fff;
        border-radius: 6px;
        width: 27px;
        height: 26px;
        text-align: center;

        font-size: 10.5pt;
        font-weight: 700;
        color: $negativeColor;
        color: lighten(#1797cb, 8%);
        color: $positiveAccentColor;
        //color: lighten(#fe4180, 14%);

        padding: 2px 0 0 0;
        box-sizing: border-box;

        margin: 0 8px 0 -4px;
        //box-shadow: 1px 1px 1px 1px darken(#e3e6f7, 0%);
        //box-shadow: 0.5px 0.5px 2px 0px darken(#f9eddc, 12%);
        border: 1.5px solid darken(#fcf6ed, 6%);
        // box-shadow: none;
        // border: 2px solid darken($backgroundColor, 6%);
        // box-sizing: border-box;
    }

    .session-key {
        //margin-left: auto;
        margin-left: 0px;
    }

    .icon-img {
        align-self: center;
        width: 26px;
        padding-right: 4px;
    }

    .plant-img {
        width: 18px;
        padding-right: 6px;
        padding-left: 0px;
        //margin-top: -1px;

        padding: 1px 4px;
    }

    .label {
        padding: 0 0 0 0px;
        font-size: 9.5pt;
        //font-size: 10.pt;
        font-weight: 700;
        color: $positiveAccentColor;
        color: lighten(#1797cb, 18%);
        // color: #111;
        // opacity: .5;
        //color: lighten($positiveAccentColor, 12%);
        //color: #999;
    }

    .divider {
        padding: 0 6px;
        font-size: 9.75pt;
        //font-size: 10.pt;
        font-weight: 600;
        color: lighten(#1797cb, 8%);
    }
}

.FadeOutEntireView {
    .main-layout {
        opacity: 0;
        transition: .5s;
    }
}

.FadeOutSessionGrid {
    .chat-label {
        transition: .7s;
        opacity: 0 !important;

    }

    .SessionGrid {
        transition: .7s;
        opacity: 0;
    }
}

.SlideOutSessionGrid {
    .chat-label {
        transition: .4s;
        opacity: 0 !important;
    }

    .SessionGrid {
        transition: .7s;
        opacity: 0;
        margin-top: 80px !important;
    }
}

.FadeInSessionGrid {
    .chat-label {
        transition: .7s;
        opacity: 1 !important;
    }

    .SessionGrid {
        transition: .7s;
        opacity: 1;
    }
}

.FadeOutNegativeGrid {
    .NegativeGrid {
        transition: .7s;
        opacity: 0;
    }
}

.HideExpandedBackground {
    .NegativeThoughtCard.Expanded {
        transition: .6s;
        background-color: $backgroundColor;

        .SessionThought {
            opacity: 0;
        }

        .response-heading {
            opacity: 0;
        }
    }

    .ResponseGrid {
        opacity: 0;
    }
}

.FadeInExpandedBackground {
    .NegativeThoughtCard.Expanded {
        transition: .6s;
        background-color: #fffdfa !important;

        .SessionThought {
            opacity: 1;
        }

        .response-heading {
            opacity: 1;
        }
    }
}

.HoldNegativeGrid {
    .NegativeGrid {
        opacity: 0 !important;
        margin-left: 80px !important;
    }
}

// .ExpandedBackgroundNoContent {
//     .NegativeThoughtCard.Expanded {

//         .SessionThought {
//             opacity: 0 !important;
//         }

//         .response-heading {
//             opacity: 0 !important;
//         }
//     }
// }

.NegativeThoughtCard.Expanded {
    transition: .6s;
}

.PostSessionPage {
    .chat-label {
        display: none;
    }

    .post-session-label {
        width: 214px;
        display: block;

        color: #25aee6;
        font-size: 25.5pt;
        //color: $negativeColor;
    }

    .expanded-chat-label {
        display: flex;
        width: 400px;

        padding: 40px 0 0 0;

        transform: scale(0.9);
        transform-origin: center left;

        .chat-img {
            width: 40px;
            margin: 0px 6px 0 54px;
            align-self: center;
        }
    }
}

.debugs {
    display: none;
    position: fixed;
    top: 70px;
    left: 260px;

    color: darken($backgroundColor, 30%);
    font-size: 10.5pt;
    font-weight: 500;
}


@keyframes messageBounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
        opacity: 1;
    }

    40% {
        transform: translateY(-10px);
    }

    60% {
        transform: translateY(-10px);
    }

    // 90% {
    //     transform: translateY(0);
    // }

    // 95% {
    //     transform: translateY(-2px);
    // }

    // 100% {
    //     transform: translateY(0);
    // }
}


@keyframes messageOneBounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
        opacity: 1;
    }

    50% {
        transform: translateY(-2px);
    }

    // 90% {
    //     transform: translateY(0);
    // }

    // 95% {
    //     transform: translateY(-2px);
    // }

    // 100% {
    //     transform: translateY(0);
    // }
}