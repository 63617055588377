@import 'global';


.YourTherapy {
    margin-top: 69px;
    margin-top: 61px;
    //margin-top: 59px;

    .areas {
        padding-top: 4px;
        padding-right: 4px;
        //margin-left: -4px;

        opacity: 1;
    }

    .stage-head {
        display: flex;
        padding: 8.0px 28px 0 32px;
    }

    .stage-title {
        padding: 0px 0 0 1px;
        font-family: 'Merriweather';
        font-weight: 500;
        font-size: 11.5pt;
        color: $negativeColor;
        color: $positiveAccentColor;
        //color: #40b8e9;
        text-align: left;
    }

    .icon-img {
        width: 15px;
        margin: 0 3px 1px 0px;
        align-self: center;
    }

    .progress-number {
        margin-left: auto;

        padding-right: 4px;
        font-size: 10.25pt;
        font-weight: 500;
        color: lighten(#16c797, 3%);
        color: $positiveAccentColor;
        //color: lighten(#ebab00, 8%);

        text-align: left;

        letter-spacing: 0.5px;

        .outof-phrase {
            display: none;
            color: lighten($positiveAccentColor, 35%);
        }

        //opacity: 0 !important;
    }

    .stage-progress {
        margin: 2px 28px 0px 32px;
    }

    .stage-progressbar {
        width: 87%;
        height: 7.5px;
        border-radius: 100px;
        background-color: lighten(#40b8e9, 35%);
        background-color: lighten($positiveAccentColor, 39%);

        margin-left: 22px;
    }

    .stage-fillbar {
        width: 60%;
        height: 7.5px;
        border-radius: 100px;
        background-color: lighten(#40b8e9, 20%);
        background-color: lighten($positiveAccentColor, 24%);
        transition: .5s;
    }

    .effort-head {
        .stage-title {
            color: lighten($reinforceColor, 10%);
            font-size: 9.5pt;
            font-weight: 600;
            font-family: "Manrope", ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
        }
    }

    .effort-progress {
        .stage-progressbar {
            //height: 4px;
            background-color: lighten($reinforceColor, 43%);
        }

        .stage-fillbar {
            width: 42%;
            //height: 4px;
            background-color: lighten($reinforceColor, 30%);
        }
    }

    .effort-head {
        padding-top: 10px;


        .progress-number {
            color: lighten($reinforceColor, 24%);
        }
    }

    .IsFirstMinor .waiting-head {
        margin-top: 2px;
        // margin-top: 8px !important;
        // border-top: 1.5px solid lighten($backgroundColor, 0%);
    }

    .waiting-head {
        //border-bottom: 1.5px solid #fdf4f0;
        margin: 0 28px 0 31px;
        margin-top: -2px;
        padding: 8px 0 0px 1px !important;

        .icon-img {
            width: 14px !important;
            //margin-left: 4px !important;
        }

        .stage-title {
            padding-left: 4px !important;
            font-size: 10pt !important;
            font-family: $sansFamily;
            font-weight: 600;
            opacity: 0.7;
        }

        .progress-number {
            padding-top: 1px !important;
            font-size: 9pt !important;
            opacity: 0.7;
        }
    }

    .waiting-progress {
        margin-top: 0px;
        margin-bottom: 2px;

        .stage-progressbar {
            height: 4.5px;
            background-color: darken($backgroundColor, 1%);
        }

        .stage-fillbar {
            width: 42%;
            height: 4.5px;
            background-color: darken($backgroundColor, 8%);
        }
    }

    .waiting-head-first {
        margin-top: 8px !important;
    }

    .area-item.Ease {
        .stage-progressbar {
            background-color: lighten($negativeColor, 55%);
        }

        .stage-fillbar {
            background-color: lighten($negativeColor, 38%);
        }

        .stage-title,
        .progress-number {
            color: lighten($negativeColor, 30%);
        }
    }

    .area-item.Purpose {
        .stage-progressbar {
            background-color: lighten(#5fd5a1, 33%);
        }

        .stage-fillbar {
            background-color: lighten(#5fd5a1, 8%);
        }

        .stage-title {
            color: darken(#5fd5a1, 15%);
        }

        .progress-number {
            color: darken(#5fd5a1, 15%);
        }
    }

    .area-item.Motivation {
        .stage-progressbar {
            background-color: lighten(#ff6142, 43%);
        }

        .stage-fillbar {
            background-color: lighten(#ff6142, 14%);
        }

        .stage-title,
        .progress-number {
            color: lighten(#ff6142, 11%);
        }
    }

    .area-item.Focus {
        .stage-progressbar {
            background-color: lighten($reinforceColor, 43%);
            background-color: lighten(#ffd9a7, 12%);
        }

        .stage-fillbar {
            background-color: lighten($reinforceColor, 33%);
            background-color: darken($backgroundColor, 6%);
            background-color: lighten($positiveAccentColor, 12%);
            background-color: darken(#ffd9a7, 4%);
        }

        .stage-title,
        .progress-number {
            color: darken(#ffd9a7, 26%);
        }
    }

    .area-item.Mood {
        .stage-progressbar {
            background-color: lighten($reinforceColor, 43%);
            background-color: lighten(#ffe681, 18%);
        }

        .stage-fillbar {
            background-color: lighten($reinforceColor, 33%);
            background-color: darken($backgroundColor, 6%);
            background-color: darken(#ffe681, 4%);
        }

        .stage-title,
        .progress-number {
            color: darken(#ffe681, 34%);
        }
    }

    .area-item.Openness {
        .stage-progressbar {
            background-color: lighten(#ffd2f5, 5%);
        }

        .stage-fillbar {
            background-color: darken(#ffd2e6, 7%);
        }

        .stage-title {
            color: darken(#ffd2e6, 20%);
        }

        .progress-number {
            color: darken(#ffd2e6, 20%);
        }
    }

}

.stage-progress {
    .stage-progressbar {
        background-color: #fff !important;
    }
}

.waiting-progress {
    .stage-fillbar {
        //opacity: 0.5 !important;
    }

    .stage-progressbar {
        background-color: #fff !important;
    }
}