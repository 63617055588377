@import 'global';



.QuestionMeter {

    width: 500px;
    margin-left: 69px;

    opacity: 0.5;

    .question-label {
        display: flex;
        font-family: 'Merriweather';
        font-size: 10pt;
        font-size: 10.5pt;
        font-weight: 700;
        //color: $negativeColor;
        color: darken($positiveAccentColor, 2%);
        color: #333;
        //color: darken($backgroundColor, 42%);
        text-align: left;
        //color: #333;
        font-weight: 400;
        margin-left: -40px;
        padding: 10px 40px 2px 0;
        line-height: 1.35em;

        .number-phrase {
            display: none;
            font-family: $sansFamily;
            font-weight: 700;
            //color: darken(#87c0ff, 6%);
        }

        .question-number {
            font-size: 10.25pt;
            margin-top: -.5px;
            padding: 0 8px 0 0;
            font-family: $sansFamily;
            font-weight: 700;
        }
    }

    .options {
        display: flex;
        //transform: scale(.95);
        margin-left: -20px;
        padding-right: 48px;
    }

    .QuestionOption {
        width: 20%;
        max-width: 20%;
        min-width: 20%;
        cursor: pointer;

        .shapes {
            display: flex;
            align-items: center;

            .track {
                opacity: .10;
                flex-grow: 1;
                height: 3px;
                background-color: lighten($positiveAccentColor, 20%);
            }

            .circle {
                opacity: .5;
                width: 14px;
                height: 14px;
                border-radius: 30px;
                background-color: darken($backgroundColor, 4%);

                border: 3px solid darken($backgroundColor, 4%);
                border: 3px solid lighten($positiveAccentColor, 20%);
                background-color: #fff;
            }

            .dot {
                opacity: 0;
                width: 8px;
                height: 8px;
                margin: 3px;
                border-radius: 30px;
                background-color: lighten($positiveAccentColor, 0%);
            }
        }

        .label {
            opacity: 0;
            padding: 0 11px 0 11px;
            margin: 8px 0 0 -0px;
            font-size: 8.75pt;
            font-size: 9.25pt;

            font-weight: 600;
            text-align: center;
            line-height: 1.2em;

            color: darken($positiveAccentColor, 0%);
            transition: .3s;
            //color: darken($backgroundColor, 34%);
            //color: #111;
        }
    }

    .QuestionOption.Selected {
        .circle {
            border: 3px solid lighten($positiveAccentColor, 0%) !important;
        }

        .dot {
            opacity: 1;
            background-color: darken($positiveAccentColor, 0%) !important;
        }

        .label {
            font-weight: 700 !important;
            opacity: 1 !important;
        }
    }

    .QuestionOption:last-of-type {
        .track {
            //opacity: 0 !important;
        }
    }

    .track-left-0,
    .track-right-4 {
        opacity: 0 !important;
    }

}

.QuestionMeter.Active:first-of-type {
    opacity: 1;

    .QuestionOption {
        .label {
            opacity: .7;
        }
    }
}

.QuestionMeter.IsCompleted.Active:first-of-type {
    .QuestionOption {
        .label {
            opacity: 0;
        }
    }
}

.QuestionOption:hover {
    .dot {
        transition: .3s;
        opacity: 1 !important;
    }

    .label {
        opacity: 1 !important;
    }
}